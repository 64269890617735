@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/gradients";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

.module {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
}
.map {
  position: fixed;
  top: spacer.$h-header;
  right: 0;
  bottom: 0;
  left: 0;
  height: 30vh;
  min-height: 35rem;
  overflow: hidden;

  @media only screen and (min-height: 600px) {
    height: 50vh;
    min-height: 55rem;
  }
  @media only screen and (min-height: 700px) {
    height: 60vh;
    min-height: 70rem;
  }
  @media only screen and (min-height: 900px) {
    height: 70vh;
    min-height: 90rem;
  }
  @media only screen and (min-height: 1200px) {
    height: 80vh;
  }

  > div:not(:first-child) {
    width: 100%;
    height: 50vh;
    min-height: 10rem;
  }
}
.title {
  position: relative;
  flex: 0 0 auto;
  z-index: z-index.$zi-content;
  margin: -8rem 0 -1rem;
  background: colors.$c-white;
  background: gradients.$gradient-white-transparent;
  padding-top: 1rem;
  min-height: 8rem;

  > h1 {
    margin: spacer.$spacer-s auto 0;
    max-width: calc(spacer.$mw-main * 0.9);
    color: colors.$c-surrogate--100;

    font-weight: fonts.$fw-medium;
    font-size: fonts.$fs-xxl;
    line-height: fonts.$lh-s;
    text-align: center;
  }
}
.content {
  position: fixed;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: z-index.$zi-content;
  background-color: colors.$c-white;
}
.loading {
  z-index: 0;
}
