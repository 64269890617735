@use "../../styles/variables/colors";

.base {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  height: 64px;
}

.link {
  transition: color 0.3s ease;
  color: #000;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.5714;
  text-decoration: none;

  &:hover {
    color: colors.$c-surrogate--100;
  }
}
