@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

.wrapper {
  position: relative;
  opacity: 0;
  z-index: z-index.$zi-content;
  transition: opacity 0.5s;
  border-radius: border.$br-l border.$br-l 0 0;
  padding-top: spacer.$spacer-s;
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include media-queries.respond-to(m) {
    margin: spacer.$spacer-l auto 0;
    max-width: spacer.$mw-main;
  }
}

.floating-modules {
  display: flex;
  position: relative;
  flex-direction: column;
  gap: spacer.$spacer-xxs;
  z-index: 1;
  margin: 0 auto;
  width: 95%;
}

.address {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
  box-shadow: colors.$bs-base;
  border-radius: border.$br-l;
  background-color: colors.$c-white;
  padding: spacer.$padding-s spacer.$padding-m;
  width: 100%;
}

.icon {
  flex: 0 0 2.5rem;
  margin-right: spacer.$spacer-s;
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.text {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  > span {
    font-weight: fonts.$fw-medium;
    white-space: nowrap;
  }
}

.map {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  > .pin {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    > img {
      width: auto;
      height: 100%;
      max-height: 4rem;
    }
  }
}
.pulse {
  animation: pulse-animation 2s infinite;
}
.show {
  opacity: 1;
}

@keyframes pulse-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
