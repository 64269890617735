@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/gradients";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

.wrapper {
  position: relative;
  padding-top: spacer.$spacer-l;
  width: 100%;
  height: auto;

  svg {
    height: 0;
  }
}

.animation {
  position: relative;
  z-index: 1;
  margin-top: -5rem;
  max-height: 60rem;
  overflow: hidden;

  > div > canvas {
    width: 100%;
    min-width: 100%;
    max-width: spacer.$mw-main;
    height: 100%;
    min-height: 40rem;
  }

  @include media-queries.respond-to(m) {
    top: 2rem;
  }
}
.mask {
  clip-path: url(#clip-path);
  margin-left: -10vw;
  width: 120vw;
  height: 120vw;

  @include media-queries.respond-to(s) {
    margin-left: 0;
    width: 400px;
    height: 400px;
  }
}
.artboard {
  background-color: colors.$c-violet--10;
  width: 100%;
  height: 100%;
}

.text {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  padding: 0 spacer.$padding-xl;
  text-align: center;

  > h3 {
    margin: 0 auto spacer.$spacer-xs;
    color: colors.$c-violet--100;
    font-weight: fonts.$fw-medium;
    font-size: fonts.$fs-s;

    > span {
      color: colors.$c-violet--60;
    }
  }
  > p {
    height: 6.6rem; //ToDo allgemeine Textlänge auf amximal 3 zeilen
    font-size: fonts.$fs-l;
  }
}
.buttonGroup {
  display: flex;
  position: fixed;
  top: auto;
  right: 0;
  bottom: 6.4rem;
  left: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: z-index.$zi-overlay;
}
.backButton {
  margin-right: spacer.$spacer-s;
}
.button {
  position: relative;
  z-index: 1;
}
#clip-path {
  width: 100%;
  height: 100%;
}
