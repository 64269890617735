@use "sass:map";
$breakpoints: (
  "xxs": 360px,
  "xs": 480px,
  "s": 568px,
  "m": 800px,
  "l": 1024px,
  "xl": 1280px,
  "xxl": 1800px,
);

@mixin respond-to($media) {
  @if $media == 0 {
    @content;
  } @else {
    @media only screen and (min-width: map.get($breakpoints, $media)) {
      @content;
    }
  }
}
