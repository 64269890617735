@use "colors";

//radius
$br-xs: 0.4rem;
$br-s: 0.6rem;
$br-m: 0.8rem;
$br-l: 1rem;
$br-xl: 4rem;

//thickness
$b-base: 2px solid colors.$c-surrogate--20;
$b-violet: 2px solid colors.$c-violet--100;
