@font-face {
  font-style: normal;
  font-weight: 400;
  src: url("./EuclidEnercity-Regular.woff2") format("woff2");
  font-family: "Euclid Enercity";
  font-display: swap;
}

@font-face {
  font-style: normal;
  font-weight: 500;
  src: url("./EuclidEnercity-Medium.woff2") format("woff2");
  font-family: "Euclid Enercity";
  font-display: swap;
}

@font-face {
  font-style: normal;
  font-weight: 700;
  src: url("./EuclidEnercity-Bold.woff2") format("woff2");
  font-family: "Euclid Enercity";
  font-display: swap;
}
