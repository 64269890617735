@use "../../styles/variables/spacer";

.animation {
  transition:
    opacity 300 ease,
    transform 300 ease;
  width: 100%;
  height: 100%;

  > div > canvas {
    width: 100%;
    min-width: 100%;
    max-width: spacer.$mw-main;
    height: 100%;
    min-height: 40rem;
  }
}
.prev {
  transform: scale(0.96);
  opacity: 0;
}
