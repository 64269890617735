@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/gradients";
@use "../../styles/variables/spacer";

.wrapper {
  display: flex;
  position: relative;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: spacer.$spacer-s;
  box-shadow: colors.$bs-base;
  border-radius: border.$br-l;
  background: gradients.$gradient-enercity; //@fritz verwende hier gerne die bestehenden gradients oder füge ein neues hinzu
  padding: spacer.$padding-s spacer.$padding-l spacer.$padding-s
    spacer.$padding-s;
  width: 100%;
}

.link {
  all: unset;
}

.appIcon {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: border.$br-s;
  background-color: colors.$c-white;
  width: 5.2rem;
  height: 5.2rem;
}

.text {
  color: colors.$c-white;
  font-weight: fonts.$fw-regular;
  line-height: fonts.$lh-s; //@fritz verwende hier gerne die bestehende Variable

  strong {
    font-weight: fonts.$fw-medium;
  }
}

.action {
  img {
    width: 20px;
    height: 20px;
  }
}
