@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/spacer";

.wrapper {
  transition: width 0.5s;
  > a,
  button {
    font-family: fonts.$ff-base;
  }
}

.button {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  transition: all 0.5s;
  cursor: pointer;
  margin: 0 auto;
  box-shadow: colors.$bs-base;
  border: none;
  border-radius: border.$br-l;
  background-color: colors.$c-surrogate--100;
  width: 100%;
  color: colors.$c-white;
  font-size: fonts.$fs-l;
  text-align: center;
  text-decoration: none;

  &:hover {
    background-color: colors.$c-surrogate--hover;
  }
}

.small {
  border-radius: border.$br-xl;
  width: max-content;
  max-width: 30rem;
  font-size: fonts.$fs-s;

  > .labelWrapper {
    padding: spacer.$padding-m spacer.$padding-xxl;
  }
}

.disabled {
  cursor: not-allowed;
  background: colors.$c-surrogate--40;

  &:hover {
    background: colors.$c-surrogate--40;
  }
}

.white,
.white-small,
.white-tiny {
  box-shadow: none;
  border: border.$b-base;
  background-color: colors.$c-white;
  color: colors.$c-surrogate--100;
  > .labelWrapper > .label {
    > span {
      color: colors.$c-surrogate--80;
    }
  }
  &:hover {
    background-color: colors.$c-surrogate--03;
  }
}

.icon {
  display: flex;
  flex: 0 1 2.4rem;
  justify-content: center;
  align-items: center;
  margin-right: spacer.$spacer-s;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.noMargin {
  margin-right: 0;
}

.label {
  flex: 1 1 auto;
  font-weight: fonts.$fw-medium;
  line-height: fonts.$lh-s;
  text-align: left;
  > .sublabel {
    display: block;
    color: colors.$c-surrogate--20;
    font-weight: fonts.$fw-regular;
    font-size: fonts.$fs-xs;
  }
}

.labelWrapper {
  display: flex;
  flex: 1 1 100%;
  flex-direction: row;
  align-items: center;
  padding: spacer.$padding-m spacer.$padding-l;
}

.white-small {
  border-radius: border.$br-xl;
  width: max-content;
  max-width: 30rem;
  font-size: fonts.$fs-s;
  > .labelWrapper {
    padding: spacer.$padding-m spacer.$padding-xxxl;
  }
}

.white-tiny {
  border-radius: border.$br-xl;
  width: max-content;
  max-width: 30rem;
  font-size: fonts.$fs-xs;
  > .labelWrapper {
    padding: spacer.$padding-s spacer.$padding-m;
  }
}

.blue {
  border: none;
  border-radius: border.$br-xl;
  background-color: colors.$c-white;
  color: colors.$c-blue--100;
  > .labelWrapper {
    padding: spacer.$padding-m 3.2rem;

    > .label {
      line-height: fonts.$lh-l;
      text-align: center;
    }
  }

  &:hover {
    background-color: colors.$c-blue--10;
  }
}

.icon-white {
  box-shadow: colors.$bs-base;
  border: transparent;
  border-radius: border.$br-xl;
  background-color: colors.$c-white;
  width: 5.3rem;
  height: 5.3rem;

  &:hover {
    background-color: colors.$c-surrogate--03;
  }
}
.disabled {
  color: colors.$c-surrogate--03;
}

.text {
  display: inline-block;
  transition: all 0.5s;
  box-shadow: none;
  border-bottom: 1.5px solid colors.$c-black--100;
  border-radius: 0;
  background-color: transparent;
  color: colors.$c-black--100;

  &:hover {
    border-color: colors.$c-surrogate--100;
    background-color: transparent;
    color: colors.$c-surrogate--100;
  }

  > .labelWrapper {
    padding: 0;

    > .label {
      width: 100%;
      font-size: fonts.$fs-xs;
      text-align: left;
    }
  }
}

.text > .centeredText,
.centeredText {
  width: max-content;
  text-align: center;
}

.payButton {
  display: flex;
  position: relative;
  flex: 0 0 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid colors.$c-surrogate--80;
  border-radius: 0 0 border.$br-l border.$br-l;
  background: linear-gradient(
    180deg,
    colors.$c-surrogate--hover 0%,
    colors.$c-surrogate--hover 67.5%
  );
  padding: 1.2rem spacer.$padding-m;
  color: colors.$c-surrogate--40;
  font-size: 1.2rem;
  line-height: fonts.$lh-s;

  @include media-queries.respond-to(xxs) {
    justify-content: flex-start;
    gap: 1rem;

    & > span {
      margin-right: auto;
    }
  }
}
