@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-index.$zi-header;
  box-shadow: colors.$bs-base;
  background-color: colors.$c-white;
  width: 100%;
  height: spacer.$h-header;
}
.logo {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}
.arrow {
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2rem;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
