//spacers
$spacer-xxs: 0.5rem;
$spacer-xs: 1rem;
$spacer-s: 1.5rem;
$spacer-m: 2.8rem;
$spacer-l: 4rem;
$spacer-xl: 5.6rem;

//padding
$padding-xs: 0.5rem;
$padding-s: 1rem;
$padding-m: 1.6rem;
$padding-l: 2rem;
$padding-xl: 2.4rem;
$padding-xxl: 2.8rem;
$padding-xxxl: 4rem;

//max
$mw-main: 40rem;

//heights
$h-header: 6.4rem;
