@use "../../styles/media-queries";
@use "../../styles/variables/spacer";

.container {
  display: block;
  position: relative;
  margin: spacer.$h-header auto 0;
  overflow-x: hidden;

  @include media-queries.respond-to(s) {
    max-width: spacer.$mw-main;
    overflow-x: visible;
  }
}
.noScroll {
  overflow-y: hidden;
  @include media-queries.respond-to(s) {
    overflow-y: visible;
  }
}
