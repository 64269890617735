@use "../fonts/fonts.scss";

//fonts
$ff-base:
  Euclid Enercity,
  sans-serif;

//font-size
$fs-xs: 1.4rem;
$fs-s: 1.5rem;
$fs-m: 1.6rem;
$fs-l: 1.7rem;
$fs-xl: 1.9rem;
$fs-xxl: 2.4rem;

//font-weight
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;

//line-height
$lh-s: 1.2;
$lh-m: 1.45;
$lh-l: 1.6;
