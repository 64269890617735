@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

.searchModule {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 13rem;
  padding: 0 spacer.$padding-l;
  width: 100%;

  @include media-queries.respond-to(s) {
    top: 60rem;
  }
}
.inputWrapper {
  position: relative;
  transition: border 0.5s;
  border: border.$b-base;
  border-radius: border.$br-l;
  width: 100%;

  &:focus-visible,
  &:focus-within {
    outline: none;
    box-shadow: 0 0 0 0.25rem rgba(colors.$c-surrogate--100, 0.12);
    border-color: colors.$c-surrogate--100;
  }
  &:focus-visible,
  &:hover {
    outline: none;
    border-color: colors.$c-surrogate--40;

    > .form > .input {
      outline: none;
      border-color: colors.$c-surrogate--40;
    }
  }
}
.form {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  > input {
    font-size: fonts.$fs-l;
  }
}
.input {
  transition: border 200ms ease;
  transition: outline 1s;
  border: transparent 0px solid;
  border-radius: border.$br-m;
  padding: spacer.$padding-m;
  width: 100%;
  height: 100%;
  color: colors.$c-surrogate--100;
  font-family: fonts.$ff-base;
  text-transform: uppercase;
  &:focus-visible,
  &:focus-within {
    outline: none;
  }
  &::placeholder {
    color: colors.$c-black--100;
    font-size: fonts.$fs-l;
    text-transform: none;
  }
}
.searchButton {
  flex: 0 0 4.6rem;
  transition: background-color 0.5s;
  cursor: pointer;
  margin: 0.3rem;
  box-shadow: colors.$bs-base;
  border-radius: border.$br-xs;
  background: colors.$c-surrogate--100;
  height: 100%;

  > div > a {
    border-radius: border.$br-xs;
    > div {
      padding: 1.2rem;
      > div,
      > div > button > div {
        margin: 0;
      }
    }
  }
}

.error {
  margin-top: 0.75rem;
  color: colors.$c-surrogate--100;
  font-weight: bold;
  font-size: 1.4rem;
}

.info {
  margin-top: 1.6rem;
  font-size: 1.4rem;
}

.separator {
  position: relative;
  margin: spacer.$spacer-s;
  padding: spacer.$padding-s;
  width: 100%;

  > p {
    display: block;
    position: relative;
    z-index: 2;
    margin: 0 auto;
    background-color: colors.$c-white;
    padding: 0 spacer.$spacer-xs;
    width: max-content;
    color: colors.$c-surrogate--40;
    text-align: center;
    text-transform: uppercase;
  }

  &::after {
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 1;
    background: radial-gradient(
      circle,
      colors.$c-surrogate--20 0%,
      rgba(colors.$c-surrogate--20, 0) 80%
    );
    width: 100%;
    height: 1.5px;
    content: "";
  }
}

.teaser {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-end;
  margin: 4.8rem 0;
  border-radius: border.$br-l;
  background: url("../../assets/Map.svg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: spacer.$padding-xxxl;
  width: 100%;
  height: 35rem;
  overflow: hidden;

  > p {
    margin-bottom: 2.2rem;
    color: colors.$c-white;
    font-weight: fonts.$fw-medium;
    font-size: fonts.$fs-xl;
    text-align: center;

    > span {
      font-weight: fonts.$fw-regular;
      font-size: fonts.$fs-m;
    }
  }
  > .button {
    margin: 0 auto;
    max-width: 17rem;
  }
}
