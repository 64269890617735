// white
$c-white: #ffffff;

// black
$c-black--100: #000000;

// surrogate
$c-surrogate--100: #c7125c;
$c-surrogate--80: #d2417d;
$c-surrogate--40: #e9a0be;
$c-surrogate--20: #f4d0df;
$c-surrogate--10: #fae8f0;
$c-surrogate--03: #fdf8fa;
$c-surrogate--hover: #b41053;

// violet
$c-violet--100: #990099;
$c-violet--60: #c266c2;
$c-violet--10: #f5e6f5;

//red
$c-red--100: #ff0000;

// blue
$c-blue--100: #14459c;
$c-blue--10: #e8edf5;

//box-shadow
$bs-base:
  0px 1px 3px rgba(0, 0, 0, 0.08),
  0px 4px 12px rgba(0, 0, 0, 0.08);
