@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

.polyComponent {
  position: relative;
  width: 100%;
}

.bg {
  position: absolute;
  z-index: 1;
  margin: 0 auto;
  width: 110vw;
  min-width: 40rem;
  max-width: 50rem;
  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  width: 100%;
}

.animation {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  z-index: 2;
  margin: -12rem 0 -8rem;
  width: 100%;
}
.icon {
  position: relative;
  z-index: 2;
  margin: spacer.$spacer-s auto;
}
.text {
  position: relative;
  z-index: 2;
  margin: 0 auto;
  max-width: 28rem;
  text-align: center;
  > h2 {
    margin-top: 0;
    margin-bottom: 0.6rem;
    color: colors.$c-violet--100;
  }
}

.button {
  margin-top: 15rem;
}
