@use "../../styles/media-queries";
@use "../../styles/variables/border";
@use "../../styles/variables/colors";
@use "../../styles/variables/fonts";
@use "../../styles/variables/spacer";
@use "../../styles/variables/z-index";

.stationControls {
  position: relative;
  z-index: z-index.$zi-content;
  margin-bottom: 36px;

  @include media-queries.respond-to(m) {
    margin: 0 auto;
    margin-bottom: spacer.$spacer-m;
    max-width: spacer.$mw-main;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: spacer.$spacer-xs;
    margin: spacer.$spacer-l auto 0;
    width: 90%;
  }

  .textButton {
    margin: spacer.$spacer-xs 0 spacer.$spacer-s;

    @media only screen and (min-height: 600px) {
      margin: spacer.$spacer-s 0 spacer.$spacer-m;
    }

    div,
    a {
      margin: 0 auto;
      width: max-content;
    }
  }
}
